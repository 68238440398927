.products h1 {
  text-align: center;
  text-transform: uppercase;
  color: #00afef;
  padding: 40px;
  padding-top: 80px;
  font-weight: lighter;
}

.products h1 b {
  font-weight: bold;
}

.products .container {
  padding-bottom: 80px;
}

.products .form-control {
  background-color: #231f20;
  color: white;
}

.products .search_number {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.card-img-top {
  width: 50%;
  margin: 15px auto;
}

.products .glide__arrow,
.modal-body .glide__arrow {
  color: #00afef;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  text-shadow: none;

  font-size: 50px;
}

.products .glide__arrow--left {
  left: -2em;
}

.products .glide__arrow--right {
  right: -2em;
}

.products .card {
  height: 280px;
}

.products .card-body {
  background-color: #231f20;
  color: white;
  border-radius: 8px;
  max-height: 120px;
  bottom: 0;
}

.products .card-img-top {
  width: 50%;
  margin: auto auto !important;
}

.products .glide__slide img {
  width: 30%;
}

.modal-body .btn-primary {
  background-color: #00afef;
  border: none;
  font-weight: bold;
  width: 30%;
  text-transform: uppercase;
}

.modal-body .description {
  display: flex;
  flex-direction: column;
}

/* remove the original arrow */
select.form-control-lg {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  font-size: 12px;
  font-weight: lighter;
  text-align-last: center;
  text-transform: uppercase;
  /* no standardized syntax available, no ie-friendly solution available */
}

select+i.fa {
  float: right;
  margin-top: -25px;
  margin-right: 5px;
  pointer-events: none;
  padding-right: 5px;
  color: #00afef;
}

.products .btn-primary {
  background-color: #00afef;
  border: none;
  font-weight: lighter;
  width: 40%;
  text-transform: uppercase;
}

.products .btn-secundary {
  background-color: #231f20;
  border: none;
  font-weight: lighter;
  width: 50%;
  text-transform: uppercase;
}

.product_card {
  height: auto !important;
}

.product_card.resume {
  background-image: radial-gradient(circle farthest-corner at 146%, #ffffff 0%, #fefefe 70%, #231f20 61%);
}

.product_card .container {
  padding: 80px 30px;
}

@media (max-width: 575.98px) {
  .products .glide__arrow--left {
    left: 0em;
  }

  .products .glide__arrow--right {
    right: 0em;
  }

  .product_card.resume {
    background-image: none
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .products .glide__arrow--left {
    left: 0em;
  }

  .products .glide__arrow--right {
    right: 0em;
  }
}