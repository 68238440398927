.quality_products {
  background-image: linear-gradient(0deg, rgba(8, 5, 0, 0.9), rgba(8, 5, 0, 0.9)), url(/static/media/hands.39aad8f1.jpg), url(../../assets/images/hands.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #231f20;
}

.quality_products h1 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  padding: 40px;
  padding-top: 80px;
  font-weight: lighter;
}

.quality_products h1 b {
  font-weight: bold;
}

.quality_products .container {
  padding-bottom: 80px;
}

.quality_products .slider_image {
  width: 60%;
}


.quality_products .btn-primary {
  background-color: #00afef;
  border: none;
  font-weight: bold;
  width: 30%;
  text-transform: uppercase;
  margin-top: 40px;
}

.glide_slides {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quality_products .glide__arrow {
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  text-shadow: none;

  font-size: 50px;
}

.quality_products .glide__arrow--left {
  left: 0em;
}

.quality_products .glide__arrow--right {
  right: 0em;
}

@media (max-width: 575.98px) {
  .quality_products .btn-primary {
    width: 60%;
  }

  .quality_products .container .row div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}