.about_us {
  background-color: #00afef;
}

.about_us h1 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  padding: 40px;
  padding-top: 80px;
  font-weight: lighter;
}

.about_us h1 b {
  font-weight: bold;
}

.about_us .container {
  min-height: 360px;
}

.split {
  width: 490px;
  position: absolute;
  top: 960px;
  right: 0;
  z-index: 0;
}

.about_us .card {
  height: 375px;
  box-shadow: 0 0 20px #0000004f;
  border: none;
}

.about_us .card-body {
  text-align: center;
}

.about_us .card-body .card-title.h5 {
  text-transform: uppercase;
  color: #00afef;
  font-weight: bold;
}

.about_us .icon {
  margin: 20px;
  font-size: 78px;
  color: #00afef;
}

.about_us .glide {
  padding: 60px 0;
}

.about_us .glide__slide {
  display: flex;
  justify-content: center;
}

.about_us .glide__slide .about_img {

  border: 4px solid white;
  box-shadow: 0 0 20px #0000004f;
  border-radius: 8px;
}

.about_us .glide__slide img {
  width: 300px;
  min-height: 223px;
}

.about_us .glide__arrow--left {
  left: -2em;
}

.about_us .glide__arrow--right {
  right: -2em;
}

.about_us .glide__arrow {
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  text-shadow: none;

  font-size: 50px;
}

.about_us .about_img {
  display: flex;
  justify-content: center;

}

.about_us .about_img p {
  position: absolute;
  padding: 6px 89px;
  color: white;
  background: #231f20;
  margin-top: 10px;
  border-radius: 3px;
}

@media (max-width: 575.98px) {
  .split {
    display: none;
  }

  .about_us .card {
    height: 270px;
  }

  .about_us .card p {
    font-size: 12px;
  }

  .about_us .glide__arrow--left {
    left: 0;
  }

  .about_us .glide__arrow--right {
    right: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .about_us .card p {
    font-size: 12px;
  }

  .split {
    width: 490px;
    position: absolute;
    top: 878px;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .about_us .card p {
    font-size: 12px;
  }

  .about_us .card {
    height: 340px;
  }

  .split {
    width: 490px;
    position: absolute;
    top: 875px;
    right: 0;
  }
}