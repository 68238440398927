.cart {
  background-color: #fff;
}

.cart h1 {
  text-align: center;
  text-transform: uppercase;
  color: #00afef;
  padding-top: 80px;

  font-weight: lighter;
}

.cart h1 b {
  font-weight: bold;
}

.cart .container {
  padding: 80px 0;
}