.footer-content {
  background-color: #231f20;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 80px 0;
}

.footer-content .container {
  padding: 18px 8%;
}

.footer__brand {
  display: flex;
  justify-content: space-around;
}

.footer__brand i {
  color: #00afef;
}

.info {
  color: #00afef;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.info .icon {
  font-size: 25px;
  margin-right: 10px;
}

.info p {
  margin: 0;
}

.footer_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer_menu h4 {
  color: white;
  text-transform: uppercase;
  font-weight: lighter;
}