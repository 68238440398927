.intro .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 40px;
  padding-top: 4%;
  border-radius: 8px;
  min-height: 440px;
  padding-bottom: 4%;
  box-shadow: 0 0 20px #cacaca;
  background-image: radial-gradient(circle farthest-corner at 146%, #ffffff 0%, #fefefe 70%, #00afef 61%);
}

.intro .glide_intro {
  min-height: 550px;
}

.intro .glide__slides {
  align-items: center;
}

.intro .glide__bullet {
  background-color: white;
  border: 1px solid #00afef;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  box-shadow: none;
}

.intro .glide__arrow {
  color: #00afef;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  text-shadow: none;
  padding: 14% 3%;
  font-size: 50px;
}

.intro .glide__bullet--active {
  background-color: #00afef;
}

.intro .glide__bullets {
  bottom: 1em;
}

.intro h2 {
  color: #00afef;
  text-transform: uppercase;
  font-weight: bold;
}

.intro .btn-primary {
  background-color: #00afef;
  border: none;
  font-weight: bold;
  width: 50%;
  text-transform: uppercase;
}

.slider_image {
  width: 90%;
}

.intro .glide__arrow--left {
  left: 0em;
}

.intro .glide__arrow--right {
  right: 0em;
}

.container+.circle {
  background-color: violet;
}

@media (max-width: 575.98px) {
  .intro .container {
    background-image: none;
  }

  .intro .container .row div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}