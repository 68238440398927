.contact_form {
  background-color: #00afef;
}

.contact_form h1 {
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  padding: 40px;
  padding-top: 80px;
}

.contact_form .container {
  padding-bottom: 80px;
  width: 40%
}

.form-control {
  border: none;
}

.btn-secundary {
  width: 100%;
  background-color: #231f20;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}

.btn-secundary:hover {
  color: white
}

@media (max-width: 575.98px) {
  .contact_form .container {
    padding-bottom: 80px;
    width: auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .contact_form .container {
    width: 70%;
  }
}