.clients h1 {
  text-align: center;
  text-transform: uppercase;
  color: #00afef;
  padding: 40px;
  padding-top: 80px;
  font-weight: lighter;
}

.clients h1 b {
  font-weight: bold;
}

.clients .row {
  justify-content: space-around;
}

.clients .container {
  padding-bottom: 80px;
}

.circle {
  width: 120px;
  height: 120px;
  border: 2px solid #00afef;
  color: #00afef;
  text-align: center;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.glide__slides {

  display: flex;
  justify-content: center;
  align-items: center;
}

.clients .client_img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
}

.clients .client_img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

@media (max-width: 575.98px) {
  .clients .client_img img {
    width: 60px;
    height: 60px;
  }
}