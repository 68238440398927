nav {
  border-bottom: 4px solid black;
}

.navbar-expand {
  margin: 0px 16%;
  justify-content: space-between;
}

.nav-icons {
  display: flex;
  align-items: center;
}

.nav-icon {
  font-size: 20px;
  color: #00afef;
  margin: 0 10px;
}

.separator {
  color: aliceblue;
  border: 1px solid #00afef;
  width: 0px;
  height: 35px;
  margin: 0;
}

.badge {
  padding: 3px 5px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  background: red;
  color: #fefefe;
  margin-top: -10px;
  position: absolute;
}

input.search {
  position: absolute;
  right: 6%;
  background: transparent;
  border: none;
  width: 30%;
}

input.search+i.fas {
  color: #00afef;
}

input.search.form-control:focus {
  background-color: transparent;
  box-shadow: none;
}

input.search:focus {
  border: none;
  border-bottom: 2px solid #00afef;
}

@media (max-width: 575.98px) {
  .navbar-expand {
    margin: auto;
  }

  input.search {
    right: 12%;
  }

}